// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/data/mockDataBookingReport.js

const mockData_test = [
    // mock data
];

const employees_test = [
    { label: 'John Doe', value: 'E001' },
    { label: 'Jane Smith', value: 'E002' }
];

const customers_test = [
    { label: 'Alice', value: 'C001' },
    { label: 'Bob', value: 'C002' },
    // other customers
];

const branches_test = [
    { label: 'Mirabeau thaï spa', value: '1' },
    { label: 'Isaan Thaï Spa', value: '2' },
    { label: 'IsaanSpa', value: '3' }
];

const statuses = [
    { label: 'Confirmed', value: '5' },
    { label: 'Pending', value: '4' },
    { label: 'Cancelled', value: '6' }
];


const services_test = [
    { label: 'Hot Oil Massage', value: 'Hot Oil Massage' },
    { label: 'Cranial Massage', value: 'Cranial Massage' },
    { label: 'Hammam + Reflexology + Choice of Massage', value: 'Hammam + Reflexology + Choice of Massage' },
    { label: 'Traditional Thai Massage', value: 'Traditional Thai Massage' }
];

const types = [
    { label: 'Solo', value: 'Solo' },
    { label: 'Duo', value: 'Duo' }
];

const fetchEmployees = async () => {
    const userId = localStorage.getItem('user_ID');
    const url = `https://py.testproject.work/register/api/all_users?user_ID=${userId}&position_ID=2`;
    const data = await fetchDataFromApi(url);
    return data.map(employee => ({
        label: employee.first_name,
        value: employee.user_ID
    }));
};

const fetchCustomers = async () => {
    const userId = localStorage.getItem('user_ID');
    const url = `https://py.testproject.work/register/api/all_users?user_ID=${userId}&position_ID=1`;
    const data = await fetchDataFromApi(url);
    return data.map(customer => ({
        label: customer.first_name,
        value: customer.user_ID
    }));
};

const fetchDataFromApi = async (url) => {
    try {
        const response = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching data from API:', error);
        return [];
    }
};

const fetchBranches = async () => {
    const data = await fetchDataFromApi('https://py.testproject.work/branches/api/view');
    return data.map(branch => ({
        label: branch.branch_name,
        value: branch.branch_ID
    }));
};

const fetchServices = async () => {
    const data = await fetchDataFromApi('https://py.testproject.work/services/api/view?status=1');
    return data.map(service => ({
        label: service.ServiceName,
        value: service.ServiceID
    }));
};

const fetchMockData = async (filters) => {
    const currentDate = new Date();
    let startDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    const endDate = new Date().toISOString().split('T')[0];

    if (filters.startDate) {
        startDate = filters.startDate.toISOString().split('T')[0];
    }

    let apiUrl = `https://py.testproject.work/report/api/booking_overview?date_from=${startDate}&date_to=${endDate}&limit=399`;

    if (filters.BranchID) {
        apiUrl += `&EmployeeBranch=${filters.BranchID}`;
    }
    if (filters.ServiceIDMain) {
        apiUrl += `&ServiceIDMain=${filters.ServiceIDMain}`;
    }
    if (filters.BookingType) {
        apiUrl += `&BookingType=${filters.BookingType}`;
    }
    if (filters.EmployeeID) {
        apiUrl += `&EmployeeID=${filters.EmployeeID}`;
    }

    if (filters.CustomerID) {
        apiUrl += `&CustomerID=${filters.CustomerID}`;
    }

    if (filters.PaymentStatus) {
        apiUrl += `&PaymentStatus=${filters.PaymentStatus}`;
    }
    if (filters.BookingStatus) {  // Add BookingStatus to the query parameters
        apiUrl += `&PaymentStatus=${filters.BookingStatus}`;
    }

    console.log('API URL:', apiUrl);

    try {
        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        const data = await response.json();
        console.log('Fetched Data:', data);

        return Array.isArray(data) ? data : [];
    } catch (error) {
        console.error('Error fetching booking report data:', error);
        return [];
    }
};

export { employees_test as employees, customers_test as customers, branches_test as branches, statuses, services_test as services, types, fetchEmployees, fetchCustomers, fetchBranches, fetchServices, fetchMockData };
