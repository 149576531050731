///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/api/api.js

const API_BASE_URL = 'https://py.testproject.work';

export const API_URLS = {
  BRANCHES: `${API_BASE_URL}/branches/api/view`,
  EVENTS: `${API_BASE_URL}/booking/api/detail_calendarView`,
  SERVICES: `${API_BASE_URL}/services/api/view?status=1`,
  PRICE: `${API_BASE_URL}/price/api/view_app`,
  CREATE_BOOKING: `${API_BASE_URL}/booking/api/create_nulllogin`,
  AVAILABLE_TIMES: `${API_BASE_URL}/booking/api/available`,
  DETAIL_CREATE: `${API_BASE_URL}/booking/api/detail_create`,
  PRICE_FOR_BOOKING: (bookingId) => `${API_BASE_URL}/price/api/view_app?PriceID=${bookingId}`,
  CHECK_AVAILABILITY: `${API_BASE_URL}/booking/api/detail_calendarView?limit=5`,
  ALL_USERS: (userID) => `${API_BASE_URL}/register/api/all_users?user_ID=${userID}&position_ID=2`
  // สามารถเพิ่มลิงค์อื่นๆ ได้ที่นี่
};
