import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown } from 'primereact';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedEmployee } from '../../redux/slices/employeeSlice';
import { selectSidebarVisible } from '../../redux/slices/sidebarSlice';

const EmployeeSelect = ({ userID, selectedEmployee, fetchReserveServiceTimes, setIsEmployeeSelected }) => {
    const [employeeData, setEmployeeData] = useState([]);
    const [storedEmployee, setStoredEmployee] = useState(null);
    const [employeeLabel, setEmployeeLabel] = useState('');
    const dispatch = useDispatch();
    const sidebarVisible = useSelector(selectSidebarVisible);

    const fetchEmployeeData = async () => {
        const apiUrl = `https://py.testproject.work/register/api/all_users?user_ID=${userID}&position_ID=2`;
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            });
            const employees = response.data.map(emp => ({
                label: `${emp.first_name} ${emp.sur_name}`,
                value: emp.user_ID
            }));
            setEmployeeData(employees);
            //employees.forEach(emp => console.log('Fetched Employee ID:', emp.value));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const getEmployeeLabel = async (employeeId) => {
        const apiUrl = `https://py.testproject.work/register/api/user?user_ID=${employeeId}`;
        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                }
            });
            const employee = response.data;
            const label = `${employee.first_name} ${employee.sur_name}`;
            console.log('Employee Label:', label);
            return label;
        } catch (error) {
            console.error('Error fetching employee label:', error);
            return '';
        }
    };

    useEffect(() => {
        fetchEmployeeData();
    }, [userID]);

    useEffect(() => {
        const fetchStoredEmployeeLabel = async () => {
            if (sidebarVisible) {
                const storedEmployee = localStorage.getItem('EventselectedEmployee');
                console.log('Stored Employee:', storedEmployee);
                setStoredEmployee(storedEmployee);
                if (storedEmployee) {
                    dispatch(setSelectedEmployee(storedEmployee));
                    const label = await getEmployeeLabel(storedEmployee);
                    setEmployeeLabel(label);
                }
            }
        };
        fetchStoredEmployeeLabel();
    }, [sidebarVisible, employeeData, dispatch]);

    const handleEmployeeChange = async (e) => {
        const selectedEmpID = e.value;
        setIsEmployeeSelected(true);
        dispatch(setSelectedEmployee(selectedEmpID));
        fetchReserveServiceTimes(selectedEmpID);
        const label = await getEmployeeLabel(selectedEmpID);
        setEmployeeLabel(label);
        setStoredEmployee(selectedEmpID);
    };

    return (
        <div>
            <label>Employee</label>
            <Dropdown  
                value={storedEmployee || selectedEmployee}
                onChange={handleEmployeeChange}
                options={employeeData}
                optionLabel="label"
                placeholder={employeeLabel || "Select an Employee"}
                className="w-full md:w-14rem"
            />
        </div>
    );
};

export default EmployeeSelect;
