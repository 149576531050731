// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/pages/Reports.js

import React, { useRef } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import BookingReport from '../components/reports/BookingReport';
import PaymentReport from '../components/reports/PaymentReport';
import ServiceReport from '../components/reports/ServiceReport';
import CustomerReport from '../components/reports/CustomerReport';

const Reports = () => {

  const location = useLocation(); // Get current location
  const clearFiltersRef = useRef(null); // Create a ref to hold the clearFilters function



  return (
    <div className="dashboard">
      <Sidebar />
      <div className="dashboard-main">
       <Header
          refreshData={() => {}}
          handleClearFilters={() => {
            if (clearFiltersRef.current) {
              clearFiltersRef.current.handleClearFilters();
            }
          }}
        />
        <Routes>
          <Route path="booking" element={<BookingReport ref={clearFiltersRef} />} />
          <Route path="payment" element={<PaymentReport />} />
          <Route path="service" element={<ServiceReport />} />
          <Route path="customer" element={<CustomerReport />} />
        </Routes>
      </div>
    </div>
  );
};

export default Reports;
