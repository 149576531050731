// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/createEventObject.js

const createEventObject = (event) => {
    const serviceDateTimeString = `${event.ServiceDate.split(' ')[1]} ${event.ServiceDate.split(' ')[2]} ${event.ServiceDate.split(' ')[3]} ${event.ServiceTime} GMT`;
    const eventDateTime = new Date(serviceDateTimeString);
    const formattedStartTime = event.ServiceTime.slice(0, 5);
    const formattedEndTime = event.EndTime.slice(0, 5);
    const amountDue = event.PriceValue - event.AmountPaid; // Calculate the remaining amount

  
    return {
      startTime: event.ServiceTime,
      endTime: event.EndTime,
      service: event.ServiceName,
      customer: `${event.EmployeeFirstName} ${event.EmployeeSurName}`,
      resourceId: event.EmployeeID.toString(),
      extendedProps: {
        serviceDetail: `${event.PriceType}`,
        date: eventDateTime.toLocaleDateString(),
        time: formattedStartTime,
        endtime: formattedEndTime,
        customerInfo: `${event.CustomerFirstName} ${event.CustomerSurName}, ${event.CustomerTelephone}`,
        customerTel: `${event.CustomerTelephone}`,
        employeeInfo: `${event.EmployeeFirstName} ${event.EmployeeSurName}, ${event.EmployeeTelephone}`,
        price: `${event.PriceValue} ${event.symbol}`,
        amountPaid: `${event.AmountPaid} ${event.symbol}`,
        amountDue: `${amountDue.toFixed(2)} ${event.symbol}`, // Add the remaining amount
        notes: `Notes: ${event.note || 'None'}`,
        serviceStatus: event.ServiceStatus,
        bookingType: event.BookingType,
        BookingID: event.BookingID, //  BookingID
        bookingDetailId: event.BookingDetailID // เพิ่ม BookingDetailID
      },
    };
  };
  
  export default createEventObject;
  