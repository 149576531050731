//  /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/DataQueues/formatEventReserve.js

const formatEventReserve = (extendedProps) => {
  // Determine the color based on amountDue
  const amountDue = parseFloat(extendedProps.amountDue.split(' ')[0]); // Extract numerical value
  const priceStyle = amountDue > 0 ? 'color:red;' : 'color:green;';
  const iconBookingType = extendedProps.bookingType === 'Solo' ? '<i class="pi pi-user"></i>' : '<i class="pi pi-user"></i><i class="pi pi-user"></i>';

  return `
  
    <p><i class="pi pi-clock"></i> Time: ${extendedProps.time} - <i class="pi pi-clock"></i> End time: ${extendedProps.endtime}\n</p>
    <p><i class="pi pi-user"></i> Cus. ${extendedProps.customerInfo}\n</p>
    <p><i class="pi pi-phone"></i> Phone: ${extendedProps.customerTel}\n</p>
    <p><i class="pi pi-calendar"></i> Date: ${extendedProps.date}\n</p>
    <p><i class="pi pi-briefcase"></i> Service: ${extendedProps.serviceDetail} ${iconBookingType} \n</p>
    <p><i class="pi pi-users"></i> Emp. ${extendedProps.employeeInfo}\n</p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Price: ${extendedProps.price} </p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Amount Paid: ${extendedProps.amountPaid} </p>
    <p><i class="pi pi-money-bill" style="${priceStyle}"></i> Amount Due : ${extendedProps.amountDue} </p>
    <p><i class="pi pi-circle-fill"></i> Reserva: ${extendedProps.BookingID}</p>
    <p><i class="pi pi-circle-fill"></i> Reserva Detail: ${extendedProps.bookingDetailId}</p>
  
  `;
};

export default formatEventReserve;