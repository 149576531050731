///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/Chart.js
import React, { useState, useEffect } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';


// Add custom color set
CanvasJSReact.CanvasJS.addColorSet("greenShades", [
  "#80d3e3",
  "#bbecf5",
  "#ffc8ca",
  "#ffc4a9",
  "#ffdfd1"
]);

const ChartComponent = ({ data, title, chartType, xTitle, yTitle, yPrefix = "", showCurrency = true }) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => setLoading(false), 1000);
  }, []);


  const options = {
    animationEnabled: true,
    theme: "light2",
    colorSet: "greenShades",
    // title: {
    //   text: title,
    //   fontSize: 16 
    // },
    axisX: {
      title: xTitle,
      valueFormatString: chartType === "column" ? "DD MMM YYYY" : "" // Proper date format for specific chart type
    },
    axisY: {
      title: yTitle,
      prefix: yPrefix, // Use the yPrefix prop here
      includeZero: false,
      interval: title === "Status" && chartType === "bar" ? 1 : undefined, // Ensure y-axis intervals are set to 1 for status bar chart
      labelFormatter: function(e) {
        return title === "Status" && chartType === "bar" ? Math.round(e.value) : e.value; // Display integers only for status bar chart
      } 
    },
    toolTip: {
      content: function(e) {
        if (chartType === "pie") {
          return `${e.entries[0].dataPoint.label}: ${e.entries[0].dataPoint.y}% (${e.entries[0].dataPoint.priceValue.toFixed(2)}€)`;
        }
        return showCurrency ? `${e.entries[0].dataPoint.y.toFixed(2)}€` : `${e.entries[0].dataPoint.y.toFixed(2)}`;
      }
    },
    backgroundColor: "transparent", // Transparent background
    data: [{
      type: chartType,
      dataPoints: data.map(point => ({
        ...point,
        indexLabel: chartType === "pie" ? `${point.label} ${point.y}%` : "", // Add label and percentage for pie chart
        indexLabelFontColor: chartType === "pie" ? "black" : "", // Set font color for index label
        indexLabelPlacement: "inside", // Place the index label inside the pie slice
        indexLabelFontSize: 12 // Adjust font size for better readability
      })),
      fillOpacity: 0.7 // Reduce fill opacity for better aesthetics
    }]
  };

  return (
    <div style={{ height: 'auto', width: '100%', position: 'relative' }}>
    {loading ? (
      <>
        <Skeleton width="100%" height="300px" />
        <ProgressSpinner style={{ width: '50px', height: '50px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />
      </>
    ) : (
      <CanvasJSChart options={options} />
    )}
  </div>
  );
};

export default ChartComponent;
