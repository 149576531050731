// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/redux/store.js

import { createStore, combineReducers } from 'redux';
import { employeeReducer } from './slices/employeeSlice';
import sidebarReducer from './slices/sidebarSlice'; // Import the sidebar reducer
import { configureStore } from '@reduxjs/toolkit';
import filtersReducer from './slices/filtersSlice';

const initialState = {
  handleSubmitClickStatus: 0,
};
const statusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_HANDLE_SUBMIT_CLICK_STATUS':
      return {
        ...state,
        handleSubmitClickStatus: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  filters: filtersReducer,
  status: statusReducer,
  employee: employeeReducer,
  sidebar: sidebarReducer, // Add the sidebar reducer
});

const store = createStore(rootReducer);

export default store;
