// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/reports/Filter/BranchFilter.js
import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const BranchFilter = ({ value, options, onChange }) => (
  <div className="filter-item">
    <Dropdown
      className={`custom-dropdown ${value ? 'selected' : ''}`}
      name="branch"
      value={value}
      options={options}
      onChange={(e) => onChange(e, 'branch')}
      placeholder="Select Branch"
      filter
    />
  </div>
);

export default BranchFilter;
