///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/EventDialog/CustomerSearch.js

import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { OverlayPanel, InputText, DataTable, Column } from 'primereact';
import styles from '../../css/OverlayPanel.module.scss';

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import MemberForm from '../MemberForm';


const CustomerSearch = ({ userID, onUserSelect }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [userData, setUserData] = useState([]);
    const searchInputRef = useRef(null);
    const op = useRef(null);

    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const [formMode, setFormMode] = useState('add'); // For form mode
    const [currentMember, setCurrentMember] = useState(null); // For passing member data to form


    useEffect(() => {
        const delayDebounce = setTimeout(() => fetchUserData(), 500);
        return () => clearTimeout(delayDebounce);
    }, [searchTerm]);

    const fetchUserData = async () => {
        if (searchTerm.trim()) {
            const apiUrl = `https://py.testproject.work/register/api/all_users?user_ID=${userID}&first_name=${searchTerm}`;
            try {
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    }
                });
                setUserData(response.data);
                op.current.toggle(searchInputRef.current);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    const handleUserSelect = (user) => {
        onUserSelect(user);
        op.current.hide();
    };

    return (
        <div>
            <label>Customers</label>
            <span className="p-input-icon-right">
                <i className="pi pi-search" />
                <InputText
                    ref={searchInputRef}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Short text in please..."
                />
            </span>

            <Button
                icon="pi pi-plus"
                className="p-button-success"
                onClick={() => setIsDialogVisible(true)}
            />

            <OverlayPanel
                ref={op}
                dismissable
                showCloseIcon
                id="overlay_panel"
                className={styles.overlayPanelCustom}
                target={searchInputRef.current}
            >
                <DataTable value={userData} selectionMode="single" onSelectionChange={e => handleUserSelect(e.value)}>
                    <Column field="first_name" header="First Name" />
                    <Column field="sur_name" header="Surname" />
                    <Column field="telephone" header="Telephone" />
                    <Column field="email" header="Email" />
                </DataTable>
            </OverlayPanel>

            <Dialog
                header="Add Member"
                visible={isDialogVisible}
                style={{ width: '50vw' }}
                onHide={() => setIsDialogVisible(false)}
                className={styles.PanelMemberForm}
            >
                <MemberForm
                    
                    memberData={currentMember}
                    mode={formMode}
                    setIsFormVisible={setIsDialogVisible}
                    onTableRefresh={() => fetchUserData()}
                    onUserSelect={handleUserSelect} // Pass handleUserSelect to MemberForm
                />
            </Dialog>



        </div>
    );
};

export default CustomerSearch;
