import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const ServiceFilter = ({ value, options, onChange }) => (
  <div className="filter-item">
    <Dropdown
      className={`custom-dropdown ${value ? 'selected' : ''}`}
      name="service"
      value={value}
      options={options}
      onChange={(e) => onChange(e, 'service')}
      placeholder="Select Service"
      filter
    />
  </div>
);

export default ServiceFilter;
