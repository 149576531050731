// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/DataQueues/EventDialogServiceTimeSidebar/PaymentMethodContainer.js

import React from 'react';
import { Col } from 'react-bootstrap';
import { InputSwitch, InputText } from 'primereact';

const PaymentMethodContainer = ({
  paymentMethodGroup2, 
  handlePaymentMethodChangeGroup2,
  paymentAmount,
  handlePaymentAmountChange,
  handleInputFocus,
  handleInputBlur,
  paymentPercentage,
  handlePaymentPercentageChange
}) => {
  return (
    <>
      <h4>Select Payment Method</h4>
      <div className="p-field-horizontal">
        <label htmlFor="online" className="payment-label">Online</label>
        <InputSwitch checked={paymentMethodGroup2 === 'online'} onChange={() => handlePaymentMethodChangeGroup2('online')} />
      </div>
      <div className="p-field-horizontal">
        <label htmlFor="store" className="payment-label">In-Store</label>
        <InputSwitch checked={paymentMethodGroup2 === 'store'} onChange={() => handlePaymentMethodChangeGroup2('store')} />
      </div>
      <div className="p-field-horizontal">
        <label htmlFor="paymentAmount">Payment Amount</label>
        <InputText id="paymentAmount" value={paymentAmount} onFocus={handleInputFocus} onBlur={handleInputBlur} onChange={(e) => handlePaymentAmountChange(e.target.value)} />
      </div>
      <h4>Select Payment Percentage</h4>

      <div className="p-field-horizontal">

        <label htmlFor="halfPayment" className="payment-label">50% Payment</label>
        <InputSwitch  className="custom-input-switch"checked={paymentPercentage === 'half'} onChange={() => handlePaymentPercentageChange('half')} />

        <label htmlFor="fullPayment" className="payment-label">100% Payment</label>
        <InputSwitch className="custom-input-switch" checked={paymentPercentage === 'full'} onChange={() => handlePaymentPercentageChange('full')} />

        <label htmlFor="NotPayment" className="payment-label">Not Payment</label>
        <InputSwitch className="custom-input-switch" checked={paymentPercentage === 'notPayment'} onChange={() => handlePaymentPercentageChange('notPayment')} />

        
      </div>

    </>
  );
};

export default PaymentMethodContainer;
