///var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/EventDialog.js

import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { openDB } from 'idb'; // Import idb for IndexedDB
import { Dialog, Button, Card, Sidebar, InputSwitch, Toast, Chip } from 'primereact';

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedEmployee } from '../redux/slices/employeeSlice';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import styles_ed from '../css/EventDialog.module.scss';
import styles from '../css/OverlayPanel.module.scss';
import '../css/SwitchEventDialog.scss';

import EventDialogServiceTimeSidebar from './DataQueues/EventDialogServiceTimeSidebar.js';
import CustomerSearch from '../components/EventDialog/CustomerSearch';
import DateOfService from '../components/EventDialog/DateOfService';
import EmployeeSelect from '../components/EventDialog/EmployeeSelect';
import ServiceTypeSidebar from '../components/EventDialog/ServiceTypeSidebar.js';

import { API_URLS } from '../api/api';

// Initialize IndexedDB
const initDB = async () => {
  return await openDB('SelectionsDB', 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains('selections')) {
        db.createObjectStore('selections', { keyPath: 'id' });
      }
    },
  });
};

const EventDialog = ({ show, onHide }) => {
  const userID = localStorage.getItem('user_ID');

  const selectedEmployee = useSelector((state) => state.employee.selectedEmployee);
  const dispatch = useDispatch();

  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState({});
  const [selectOptions, setSelectOptions] = useState([]);
  const [date, setDate] = useState('');
  const [selectedDateEvent, setSelectedDateEvent] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedPriceFor, setSelectedPriceFor] = useState('');
  const [priceData, setPriceData] = useState([]);
  const [cumulativePrice, setCumulativePrice] = useState(0);
  const [priceSelections, setPriceSelections] = useState({});
  const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedBookingTime, setSelectedBookingTime] = useState('');
  const [selectedPriceID, setSelectedPriceID] = useState(null);
  const [selectedServiceTimesCount, setSelectedServiceTimesCount] = useState(0);
  const [reserveServiceTimes, setReserveServiceTimes] = useState([]);

  // Add state to manage the visibility of the top sidebar
  const [topSidebarVisible, setTopSidebarVisible] = useState(false);
  const [amountPaid, setAmountPaid] = useState(0);
  const [isEmployeeSelected, setIsEmployeeSelected] = useState(false); // Add this state

  const [serviceTimeFrom, setServiceTimeFrom] = useState('');
  const [serviceTimeTo, setServiceTimeTo] = useState('');

  const toast = useRef(null);
  const firstRender = useRef(true); // Define the firstRender ref

  useEffect(() => {
    fetchServiceOptions();
    loadSelectionsFromDB();
    setSelectedDateEvent(new Date().toISOString().slice(0, 10)); // Initialize to current date
  }, []);

  // Function to update AmountPaid
  const updateAmountPaid = (amount) => {
    setAmountPaid(amount);
  };

  const fetchServiceOptions = async () => {
    try {
      const response = await axios.get(API_URLS.SERVICES);
      if (response.data && Array.isArray(response.data)) {
        const options = response.data.map(item => ({
          label: `${item.ServiceName} - ${item.ServiceTypes_Name}`,
          value: item.ServiceID
        }));
        setSelectOptions(options);
      } else {
        console.log('No data received from the API');
      }
    } catch (error) {
      console.error('Error fetching service options:', error);
    }
  };

  const fetchPriceData = async (selectedValue) => {
    try {
      const apiParams = { status: 1, TableData_ID: 3, priceConn_dataID: selectedValue, PriceFor: selectedPriceFor };
      const response = await axios.get(API_URLS.PRICE, {
        params: apiParams
      });
      setPriceData(response.data.prices || []);

      setPriceSelections((response.data.prices || []).reduce((acc, item) => ({
        ...acc,
        [item.PriceID]: false
      }), {}));
    } catch (error) {
      console.error('Error fetching price data:', error);
    }
  };

  const refreshServiceTimes = () => {
    firstRender.current = true;
    fetchReserveServiceTimes(selectedDateEvent, selectedPriceID, (slots) => {
      setReserveServiceTimes(slots);
    });
  };

  const handleInputSwitchChange = async (priceID, priceValue) => {
    setPriceSelections(prevState => {
      const newState = { ...prevState, [priceID]: !prevState[priceID] };
      const priceAdjustment = newState[priceID] ? priceValue : -priceValue;

      setCumulativePrice(prevTotal => Math.max(0, prevTotal + priceAdjustment));
      setRightSidebarVisible(newState[priceID]);
      setSelectedPriceID(priceID);
      setSelectedServiceTimesCount(prevCount => prevCount + (newState[priceID] ? 1 : -1));

      if (newState[priceID]) {
        fetchReserveServiceTimes(selectedDateEvent, priceID, setReserveServiceTimes, selectedEmployee);
      }

      sessionStorage.setItem('tempPriceSelections', JSON.stringify(newState));
      sessionStorage.setItem('tempCumulativePrice', JSON.stringify(cumulativePrice + priceAdjustment));
      saveSelectionToDB(priceID, newState[priceID]);

      setTopSidebarVisible(true);
      firstRender.current = true;

      return newState;
    });
  };

  const saveSelectionToDB = async (priceID, isSelected) => {
    const db = await initDB();
    const tx = db.transaction('selections', 'readwrite');
    const store = tx.objectStore('selections');
    await store.put({ id: priceID, selected: isSelected });
    await tx.done;
  };

  const loadSelectionsFromDB = async () => {
    const db = await initDB();
    const tx = db.transaction('selections', 'readonly');
    const store = tx.objectStore('selections');
    const allSelections = await store.getAll();
    const newSelections = allSelections.reduce((acc, item) => {
      acc[item.id] = item.selected;
      return acc;
    }, {});
    setPriceSelections(newSelections);
  };

  const handleSelectButtonClick = () => {
    setSidebarVisible(true);
  };

  const handleSelectOptionChange = (e) => {
    if (!selectedUser.user_ID) {
      showToast('error', 'Error', 'Please select a customer.');
      return;
    }
    if (!selectedDateEvent) {
      showToast('error', 'Error', 'Please select a date of service.');
      return;
    }
    if (!selectedEmployee) {
      showToast('error', 'Error', 'Please select an employee.');
      return;
    }
    if (!selectedPriceFor) {
      showToast('error', 'Error', 'Please select Solo or Duo.');
      return;
    }
    setSelectedService(e.value);
    fetchPriceData(e.value);
    setSidebarVisible(true);
  };

  const handlePriceForSelect = (value) => {
    if (!selectedUser.user_ID) {
      showToast('error', 'Error', 'Please select a customer.');
      return;
    }
    if (!selectedDateEvent) {
      showToast('error', 'Error', 'Please select a date of service.');
      return;
    }
    if (!selectedEmployee) {
      showToast('error', 'Error', 'Please select an employee.');
      return;
    }
    setSelectedPriceFor(value);
    fetchServiceOptions();
  };


  // Update checkAvailability to use serviceTimeFrom and serviceTimeTo
const checkAvailability = async (dateFrom, dateTo, employeeID) => {
  const token = localStorage.getItem('token'); // Assuming you store the token in localStorage

  console.log('Checking availability with params:', {
    date_from: dateFrom,
    date_to: dateTo,
    EmployeeID: employeeID,
    ServiceTime_from: serviceTimeFrom,
    ServiceTime_to: serviceTimeTo
  });

  try {
    const params = {
      date_from: dateFrom,
      date_to: dateTo,
      EmployeeID: employeeID,
      ServiceTime_from: serviceTimeFrom,
      ServiceTime_to: serviceTimeTo
    };

    const response = await axios.get(API_URLS.CHECK_AVAILABILITY, {
      params,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('Availability check response:', response.data);

    if (response.data && response.data.message === 'No booking details found') {
      return true; // No bookings found in the given time slot, so it's available
    } else {
      showToast('error', 'Error', 'The selected time slot is not available. Please select a different employee.');
      setIsEmployeeSelected(false); // Reset employee selection state
      return false;
    }
  } catch (error) {
    console.error('Error checking availability:', error);
    showToast('error', 'Error', 'Failed to check availability. Please select a different employee.');
    setIsEmployeeSelected(false); // Reset employee selection state
    return false;
  }
};


  const handleSubmit = async (paymentAmount) => {
    const token = localStorage.getItem('token'); // Define the token here
    // Check availability before proceeding
    const isAvailable = await checkAvailability(
      selectedDateEvent,
      selectedDateEvent,
      selectedEmployee,
      selectedTime,
      selectedTime
    );

    if (!isAvailable) {
      return; // Stop execution if not available
    }

    const paymentStatus = paymentAmount === cumulativePrice ? 5 : 4;
    const bookingData = {
      CustomerID: selectedUser.user_ID || searchTerm,
      TotalAmount: cumulativePrice,
      AmountPaid: paymentAmount || 0,
      BookingStatus: 5,
      PaymentStatus: paymentStatus,
      date: selectedDateEvent,
      time: selectedTime || new Date().toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' })
    };

    try {
      const response = await fetch(API_URLS.CREATE_BOOKING, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` // Use the token here
        },
        body: JSON.stringify(bookingData)
      });

      if (!response.ok) {
        const responseBody = await response.text();
        throw new Error('Failed to create booking');
      }

      const bookingResponse = await response.json();

      if (bookingResponse.Bookings && bookingResponse.Bookings.length > 0) {
        const bookingID = bookingResponse.Bookings[0].BookingID;
        const transformedBookings = await transformSelectedBookingsForSubmission(bookingID);
        await submitTransformedBookingsToDetailCreate(transformedBookings);
      } else {
        console.error('No booking details found');
      }

    } catch (error) {
      console.error('Error submitting booking:', error);
      showToast('error', 'Error', 'There was an error creating the booking.');
    }
  };




  const transformSelectedBookingsForSubmission = async (bookingID) => {
    const transformedBookings = [{
      BookingID: bookingID,
      ServiceID: selectedPriceID,
      EmployeeID: selectedEmployee,
      ServiceDate: formatDateForSubmission(selectedDateEvent),
      ServiceTime: selectedBookingTime,
      ServiceStatus: 1,
      TransactionID: 0,
      GiftRecipientID: 0,
      note: "Special instructions here",
      promotion_id: 0,
      BookingDetailPrice: parseFloat(await fetchPriceValueForBooking(selectedPriceID))
    }];



    const bookingDateTime = new Date(`${transformedBookings[0].ServiceDate}T${transformedBookings[0].ServiceTime}:00`);
    const dayOfWeek = bookingDateTime.getDay();
    const hour = bookingDateTime.getHours();

    if (dayOfWeek !== 0 && dayOfWeek !== 6 && hour >= 11 && hour < 15) {
      transformedBookings[0].promotion_id = 41;
      transformedBookings[0].BookingDetailPrice = (transformedBookings[0].BookingDetailPrice * 0.85).toFixed(2);
    }

    return transformedBookings;
  };

  const handleEmployeeChange = (e) => {
    const selectedEmpID = e.value;
    setIsEmployeeSelected(true); // Set this state when an employee is selected
    dispatch(setSelectedEmployee(selectedEmpID));
    fetchReserveServiceTimes(selectedEmpID);
  };


  const resetForm = () => {
    setSelectedUser({});
    setSearchTerm('');
    setSelectedDateEvent(new Date().toISOString().slice(0, 10)); // Set to current date
    setSelectedTime('');
    setSelectedPriceFor('');
    setPriceData([]);
    setCumulativePrice(0);
    setPriceSelections({});
    setSelectedService(null);
    setSelectedBookingTime('');
    setSelectedPriceID(null);
    setSelectedServiceTimesCount(0);
    setReserveServiceTimes([]);

    // Reset employee only if it was selected by the user
    if (isEmployeeSelected) {
      setIsEmployeeSelected(false); // Reset the state after clearing
    }

  };

  const submitTransformedBookingsToDetailCreate = async (transformedBookings) => {
    try {
      const response = await fetch(API_URLS.DETAIL_CREATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(transformedBookings)
      });

      if (!response.ok) {
        const responseBody = await response.text();
        throw new Error('Failed to create booking details');
      }

      const detailResponse = await response.json();
      showToast('success', 'Success', 'Booking details created successfully.');

      // Reset form fields upon successful booking
      resetForm();

    } catch (error) {
      console.error('Error creating booking details:', error);
      showToast('error', 'Error', 'There was an error creating the booking details.');
    }
  };

  const formatDateForSubmission = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const fetchPriceValueForBooking = async (bookingId) => {
    try {
      const response = await fetch(API_URLS.PRICE_FOR_BOOKING(bookingId)); // Use the function correctly
      const data = await response.json();
      const priceValue = data.prices.length > 0 ? data.prices[0].PriceValue : 0;
      return priceValue;
    } catch (error) {
      console.error(`Error fetching price for booking id ${bookingId}:`, error);
      return 0;
    }
  };

  const handleSelectedBookingTime = (time) => {
    setSelectedBookingTime(time);
    console.log('handleSelectedBookingTime Selected time:', time);

    // Assuming serviceTimeFrom and serviceTimeTo are state variables or variables in the scope
    setServiceTimeFrom(time);
    setServiceTimeTo(time);
  };

  const showToast = (severity, summary, detail) => {
    toast.current.show({ severity, summary, detail, life: 3000 });
  };


  const fetchReserveServiceTimes = async (date, priceID, setReserveServiceTimes, employeeID) => {
    try {
      // Get employeeID from localStorage if not provided
      if (!employeeID) {
        employeeID = localStorage.getItem('EventselectedEmployee');
      }

      // Log data before sending to the API
      const requestData = {
        date_from: date,
        date_to: date,
        //ServiceID: priceID,
        EmployeeID: employeeID
      };
      const response = await axios.get(API_URLS.AVAILABLE_TIMES, {
        params: requestData
      });

      const slots = createReserveServiceTimes(response.data, date);
      setReserveServiceTimes(slots);
      sessionStorage.setItem('reserveServiceTimes', JSON.stringify(slots));
    } catch (error) {
      console.error('Failed to fetch time slots:', error);
      const emptySlots = createReserveServiceTimes({});
      setReserveServiceTimes(emptySlots);
      sessionStorage.setItem('reserveServiceTimes', JSON.stringify(emptySlots));
    }
  };



  const createReserveServiceTimes = (apiData, date) => {
    const slots = [];
    const availableTimes = apiData[date] || [];

    for (let hour = 11; hour <= 21; hour++) {
      const hourSlot = `${hour.toString().padStart(2, '0')}:00`;
      const halfHourSlot = `${hour.toString().padStart(2, '0')}:30`;

      const isHourAvailable = availableTimes.some(timeSlot => timeSlot.time === hourSlot && timeSlot.status === 'available');
      const isHalfHourAvailable = availableTimes.some(timeSlot => timeSlot.time === halfHourSlot && timeSlot.status === 'available');

      slots.push({ time: hourSlot, statusHH: isHourAvailable ? "Available" : "Unavailable" });
      if (hour < 21) {
        slots.push({ time: halfHourSlot, statusHH: isHalfHourAvailable ? "Available" : "Unavailable" });
      }
    }
    return slots;
  };

  const handleServiceTypeSidebarHide = () => {
    setSidebarVisible(false);
    resetServiceTypeSelections();
  };

  const resetServiceTypeSwitches = () => {
    Object.keys(priceSelections).forEach(priceID => {
      handleInputSwitchChange(priceID, false);
    });
  };

  const resetServiceTypeSelections = () => {
    setPriceSelections({});
  };

  const clearCumulativePrice = () => {
    setCumulativePrice(0);
    setPriceSelections({});
  };

  const resetFirstRender = () => {
    firstRender.current = true;
  };

  // Add this function inside EventDialog component
  const fetchReserveServiceTimesWithEmployee = (employeeID) => {
    // Check if employeeID is valid before calling fetchReserveServiceTimes
    if (employeeID) {
      console.log('Fetching with EmployeeID:', employeeID); // Log the employee ID
      fetchReserveServiceTimes(selectedDateEvent, selectedPriceID, setReserveServiceTimes, employeeID);
    } else {
      console.error('Invalid employeeID:', employeeID);
    }
  };

  return (
    <>
      <Toast ref={toast} />

      <ServiceTypeSidebar
        visible={sidebarVisible}
        onHide={() => {
          setSidebarVisible(false);
          sessionStorage.setItem('tempPriceSelections', JSON.stringify(priceSelections));
          sessionStorage.setItem('tempCumulativePrice', JSON.stringify(cumulativePrice));
          handleServiceTypeSidebarHide(); // Pass the onHide function to ServiceTypeSidebar
          // Remove the logic to reset the employee when hiding the sidebar
        }}
        priceData={priceData}
        priceSelections={priceSelections}
        handleInputSwitchChange={handleInputSwitchChange}
        setRightSidebarVisible={setRightSidebarVisible}
        clearCumulativePrice={clearCumulativePrice}
        maxServiceTimes={selectedServiceTimesCount} // Pass selectedServiceTimesCount
        resetFirstRender={resetFirstRender} // Pass resetFirstRender as a prop
        refreshServiceTimes={refreshServiceTimes} // Pass refreshServiceTimes as a prop
      />

      <EventDialogServiceTimeSidebar
        isSidebarVisible={rightSidebarVisible}
        onHide={() => {
          setRightSidebarVisible(false);
          resetServiceTypeSelections(); // Reset InputSwitch values
          sessionStorage.setItem('tempReserveServiceTimes', JSON.stringify(reserveServiceTimes));
          clearCumulativePrice(); // Clear cumulative price when the sidebar is hidden
        }}
        hideServiceTypeSidebar={() => setSidebarVisible(false)} // Pass the onHide function
        selectedDate={selectedDateEvent}
        handleSelectedBookingTime={handleSelectedBookingTime}
        maxServiceTimes={selectedServiceTimesCount}
        showToast={showToast}
        priceID={selectedPriceID}
        fetchReserveServiceTimes={fetchReserveServiceTimes}
        setReserveServiceTimes={setReserveServiceTimes}
        cumulativePrice={cumulativePrice} // Pass cumulativePrice as a prop        
        handleSubmit={handleSubmit} // Pass handleSubmit as a prop
        onHideDialog={onHide} // Pass the onHide function to close the dialog
        clearCumulativePrice={clearCumulativePrice} // Pass the clearCumulativePrice function as a prop
        updateAmountPaid={updateAmountPaid} // Pass the new prop to update AmountPaid
        selectedEmployee={selectedEmployee} // Pass selectedEmployee as a prop
      />

      <Dialog visible={show} className={styles_ed.customDialog} header="Event Details" modal onHide={onHide} >

        <Container className={styles_ed.dialogContainer}>
          <Card className={`${styles_ed.bgindex2}`}>
            <Row className="justify-content-md-center mt-2">
              <Col xs={12} md={12} >
                <Chip label={`First Name: ${selectedUser.first_name || ''}`} className={`ms-2 ${styles_ed.chipindex1}`} />
                <Chip label={`Last Name: ${selectedUser.sur_name || ''}`} className={`ms-2 ${styles_ed.chipindex1}`} />
                <Chip label={`Phone: ${selectedUser.telephone || ''}`} className={`ms-2 ${styles_ed.chipindex1}`} />
                <Chip label={`Email: ${selectedUser.email || ''}`} className={`ms-2 ${styles_ed.chipindex1}`} />
              </Col>
            </Row>

            <Row className="justify-content-md-center mt-4">
              <Col xs={12} md={12} className={styles.searchSection}>
                <Row>
                  <Col xs={4}>
                    <CustomerSearch userID={userID} onUserSelect={setSelectedUser} />
                  </Col>
                  <Col xs={4}>
                    <DateOfService selectedDateEvent={selectedDateEvent} setSelectedDateEvent={setSelectedDateEvent} />
                  </Col>
                  <Col xs={4}>
                    <EmployeeSelect
                      userID={userID}
                      selectedEmployee={selectedEmployee}
                      setIsEmployeeSelected={setIsEmployeeSelected} // Pass the state updater function
                      fetchReserveServiceTimes={fetchReserveServiceTimesWithEmployee}
                    />

                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>

          <Row className='mt-5'>
            <Col xs={12} md={6}>
              <Row className="gx-2">
                <Col xs={6} className="d-flex justify-content-center">
                  <Button label="Solo"
                    className={`${styles_ed.bigButton} ${selectedPriceFor === 'Solo' ? styles_ed.selectedButton : ''} ${styles_ed.bgindex1}`}
                    icon={<FontAwesomeIcon icon={faUser} />}
                    onClick={() => handlePriceForSelect('Solo')}
                    iconPos="left" />
                </Col>
                <Col xs={6} className="d-flex justify-content-center">
                  <Button label="Duo"
                    className={`${styles_ed.bigButton} ${selectedPriceFor === 'Duo' ? styles_ed.selectedButton : ''} ${styles_ed.bgindex1}`}
                    icon={<FontAwesomeIcon icon={faUserGroup} />}
                    onClick={() => handlePriceForSelect('Duo')}
                    iconPos="left" />
                </Col>
              </Row>
              <Col className='mt-2' xs={12} md={12} >
                <Card className={styles_ed.cardHover}>
                  <p>Cumulative Total: ${cumulativePrice}</p>
                </Card>
              </Col>
            </Col>
            <Col xs={6} md={6}>
              <Row>
                {selectOptions.map((option, index) => (
                  <Col className='mt-4' xs={4} md={4} key={index}>
                    <Button
                      className={`${styles_ed.selectButtonCustom} ${selectedService === option.value ? styles_ed.activeButton : ''}`}
                      label={option.label}
                      onClick={() => handleSelectOptionChange({ value: option.value })}
                      style={selectedService === option.value ? { backgroundColor: '#19f081' } : {}}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>

        </Container>
      </Dialog>

    </>
  );
};

export default EventDialog;
