// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/reports/Filter/EmployeeFilter.js
import React from 'react';
import { Dropdown } from 'primereact/dropdown';

const EmployeeFilter = ({ value, options, onChange }) => {
  return (
    <div className="filter-item">
      <Dropdown
        className={`custom-dropdown ${value ? 'selected' : ''}`}
        name="employee"
        value={value}
        options={options}
        onChange={(e) => onChange(e, 'employee')}
        placeholder="Select Employee"
        filter
      />
    </div>
  );
};

export default EmployeeFilter;
