// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/reports/FiltersBookingReport.js

import React, { useRef, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';
import '../../css/BookingReport.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearFilters, requestClearFilters, clearRequestedHandled } from '../../redux/slices/filtersSlice';
import { fetchMockData, fetchEmployees, fetchCustomers, fetchBranches } from '../../data/mockDataBookingReport';
import BranchFilter from './Filter/BranchFilter';
import ServiceFilter from './Filter/ServiceFilter';
import TypeFilter from './Filter/TypeFilter';
import TimeFilter from './Filter/TimeFilter';
import EmployeeFilter from './Filter/EmployeeFilter';
import CustomerFilter from './Filter/CustomerFilter';
import StatusFilter from './Filter/StatusFilter';

const Filters = ({
  filters,
  setFilters,
  timeFilters,
  setTimeFilters,
  hours,
  branchesOptions,
  statusesOptions,
  servicesOptions,
  typesOptions,
  clearFilters,
  setData
}) => {
  const dispatch = useDispatch();
  const filtersState = useSelector(state => state.filters);
  const op = useRef(null);
  const [selectedTimeFilterLabel, setSelectedTimeFilterLabel] = useState('Time Filters');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [selectedDropdown, setSelectedDropdown] = useState({});
  const [activeButton, setActiveButton] = useState(false);

  const [employeesOptions, setEmployeesOptions] = useState([]);
  const [customersOptions, setCustomersOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  const addAllDataOption = (options) => {
    if (!options.some(option => option.label === 'All Data')) {
      return [{ label: 'All Data', value: null }, ...options];
    }
    return options;
  };

  useEffect(() => {
    const initializeData = async () => {
      const employeesData = await fetchEmployees();
      setEmployeesOptions(addAllDataOption(employeesData));

      const customersData = await fetchCustomers();
      setCustomersOptions(addAllDataOption(customersData));

      setStatusOptions([...new Set(addAllDataOption(statusesOptions))]);
    };

    initializeData();
  }, []);

  useEffect(() => {
    if (filtersState.clearRequested) {
      handleClearFilters();
      dispatch(clearRequestedHandled());
    }
  }, [filtersState.clearRequested, dispatch]);

  const handleDropdownChange = async (e, name) => {
    let updatedFilters = { ...filters };

    if (name === 'branch') {
      updatedFilters = {
        ...updatedFilters,
        BranchID: e.value
      };
    } else if (name === 'service') {
      updatedFilters = {
        ...updatedFilters,
        ServiceIDMain: e.value
      };
    } else if (name === 'type') {
      updatedFilters = {
        ...updatedFilters,
        BookingType: e.value
      };
    } else if (name === 'employee') {
      updatedFilters = {
        ...updatedFilters,
        EmployeeID: e.value
      };
    } else if (name === 'customer') {
      updatedFilters = {
        ...updatedFilters,
        CustomerID: e.value
      };
    } else if (name === 'paymentStatus') {
      updatedFilters = {
        ...updatedFilters,
        PaymentStatus: e.value
      };
    } else if (name === 'status') {
      updatedFilters = {
        ...updatedFilters,
        BookingStatus: e.value
      };
    } else {
      updatedFilters = {
        ...updatedFilters,
        [name]: e.value
      };
    }
    setFilters(updatedFilters);
    setSelectedDropdown({ ...selectedDropdown, [name]: true });

    const result = await fetchMockData(updatedFilters);
    setData(result);
  };

  const handleButtonClick = () => {
    setActiveButton(!activeButton);
  };

  const handleButtonClickWithToggle = (e) => {
    handleButtonClick();
    op.current.toggle(e);
  };

  useEffect(() => {
    const initialLabel = () => {
      if (timeFilters.oneHour) return '1 Hour';
      if (timeFilters.twelveHours) return '12 Hours';
      if (timeFilters.twentyFourHours) return '24 Hours';
      if (timeFilters.sevenDays) return '7 Days';
      if (timeFilters.thirtyDays) return '30 Days';
      if (timeFilters.threeMonths) return '3 Months';
      if (timeFilters.twelveMonths) return '12 Months';
      if (timeFilters.customDate) return 'Custom Date';
      if (timeFilters.customTime) return 'Custom Time';
      return 'Time Filters';
    };
    setSelectedTimeFilterLabel(initialLabel());
  }, [timeFilters]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value
    });
  };

  const handleClearFilters = async () => {
    setFilters({
      employee: null,
      customer: null,
      BranchID: '1',
      status: null,
      service: null,
      type: null,
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      startTime: null,
      endTime: null
    });

    setTimeFilters({
      oneHour: false,
      twelveHours: false,
      twentyFourHours: false,
      sevenDays: false,
      thirtyDays: true,
      threeMonths: false,
      twelveMonths: false,
      customDate: false,
      customTime: false,
    });

    const result = await fetchMockData({
      BranchID: '1',
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
    });
    setData(result);
  };

  return (
    <div className="filter-col">
      <Form>
        <div className="filters-container">
          <BranchFilter
            value={filters.BranchID}
            options={branchesOptions}
            onChange={handleDropdownChange}
          />

          <ServiceFilter
            value={filters.service}
            options={servicesOptions}
            onChange={handleDropdownChange}
          />

          <TypeFilter
            value={filters.type}
            options={typesOptions}
            onChange={handleDropdownChange}
          />

          <div className="filter-item">
            <Button
              className={`custom-dropdown ${activeButton ? 'selected' : ''}`}
              type="button"
              onClick={handleButtonClickWithToggle}
            >
              {selectedTimeFilterLabel}
            </Button>
            <TimeFilter
              op={op}
              timeFilters={timeFilters}
              setTimeFilters={setTimeFilters}
              filters={filters}
              setFilters={setFilters}
              handleFilterChange={handleFilterChange}
              hours={hours}
              setSelectedTimeFilterLabel={setSelectedTimeFilterLabel}
              setActiveButton={setActiveButton}
              fetchMockData={fetchMockData}
              setData={setData}
            />
          </div>

          <EmployeeFilter
            value={filters.employee}
            options={employeesOptions}
            onChange={handleDropdownChange}
          />

          <CustomerFilter
            value={filters.customer}
            options={customersOptions}
            onChange={handleDropdownChange}
          />

          <StatusFilter
            value={filters.status}
            options={statusOptions}
            onChange={handleDropdownChange}
          />

<Button variant="secondary" onClick={handleClearFilters}>
          Refresh
        </Button>
        
        </div>

        
      </Form>

      
    </div>
  );
};

export default Filters;
