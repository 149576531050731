// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/DataQueues/fetchResources.js
import { API_URLS } from '../../api/api'; // Adjust the import path as necessary

const fetchResources = async (setResources, branchID) => {
  const userID = localStorage.getItem('user_ID');
  const commonHeaders = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('token')}`,
  };

  try {
    let url = API_URLS.ALL_USERS(userID);
    if (branchID !== 'all') {
      url += `&branch_ID=${branchID}`;
    }

    const response = await fetch(url, {
      headers: commonHeaders,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    const calendarResources = data.map((user) => ({
      id: user.user_ID.toString(),
      title: user.first_name,
    }));

    setResources(calendarResources);
  } catch (error) {
    console.error('Error fetching resources:', error);
  }
};

export default fetchResources;
