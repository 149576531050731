// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/redux/slices/filtersSlice.js
const CLEAR_FILTERS = 'filters/clearFilters';
const REQUEST_CLEAR_FILTERS = 'filters/requestClearFilters';
const CLEAR_REQUESTED_HANDLED = 'filters/clearRequestedHandled';

export const clearFilters = () => ({ type: CLEAR_FILTERS });
export const requestClearFilters = () => ({ type: REQUEST_CLEAR_FILTERS });
export const clearRequestedHandled = () => ({ type: CLEAR_REQUESTED_HANDLED });

const initialState = {
  filters: {
    clearRequested: false,
  },
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FILTERS:
      console.log('clearFilters');
      return state; // No state modification needed
    case REQUEST_CLEAR_FILTERS:
      console.log('requestClearFilters');
      return {
        ...state,
        filters: {
          ...state.filters,
          clearRequested: true,
        },
      };
    case CLEAR_REQUESTED_HANDLED:
      return {
        ...state,
        filters: {
          ...state.filters,
          clearRequested: false,
        },
      };
    default:
      return state;
  }
};

export default filtersReducer;
