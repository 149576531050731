// /var/www/website2024/dev.afaa.website/s-293-spatime-admin/src/components/reports/Filter/TimeFilter.js
import React from 'react';
import { Form } from 'react-bootstrap';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Dropdown } from 'primereact/dropdown';
import { OverlayPanel } from 'primereact/overlaypanel';

const TimeFilter = ({ op, timeFilters, setTimeFilters, filters, setFilters, handleFilterChange, hours, setSelectedTimeFilterLabel, setActiveButton, fetchMockData, setData }) => {
  
  const handleTimeChange = async (name, value) => {
    const newTimeFilters = { ...timeFilters };
    const newFilters = { ...filters };

    if (name === 'customTime') {
      newTimeFilters.customTime = value;
      if (value) {
        newTimeFilters.twentyFourHours = true;
        newTimeFilters.sevenDays = false;
        newTimeFilters.thirtyDays = false;
        newTimeFilters.threeMonths = false;
        newTimeFilters.twelveMonths = false;
        newTimeFilters.customDate = false;
      } else {
        newTimeFilters.twentyFourHours = false;
      }
    } else if (newTimeFilters.customTime) {
      Object.keys(newTimeFilters).forEach(key => {
        if (['sevenDays', 'thirtyDays', 'threeMonths', 'twelveMonths', 'customDate'].includes(key) && key !== name) {
          newTimeFilters[key] = false;
        }
      });
      newTimeFilters[name] = value;
    } else {
      Object.keys(newTimeFilters).forEach(key => {
        if (key !== name) newTimeFilters[key] = false;
      });
      newTimeFilters[name] = value;
    }

    if (value) {
      const currentDate = new Date();
      let label;
      switch (name) {
        case 'twentyFourHours':
          newFilters.startDate = new Date(currentDate.getTime() - 24 * 60 * 60 * 1000);
          newFilters.endDate = currentDate;
          label = '24 Hours';
          break;
        case 'sevenDays':
          newFilters.startDate = new Date(currentDate.getTime() - 7 * 24 * 60 * 60 * 1000);
          newFilters.endDate = currentDate;
          label = '7 Days';
          break;
        case 'thirtyDays':
          newFilters.startDate = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
          newFilters.endDate = currentDate;
          label = '30 Days';
          break;
        case 'threeMonths':
          newFilters.startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 3));
          newFilters.endDate = new Date();
          label = '3 Months';
          break;
        case 'twelveMonths':
          newFilters.startDate = new Date(currentDate.setMonth(currentDate.getMonth() - 12));
          newFilters.endDate = new Date();
          label = '12 Months';
          break;
        default:
          break;
      }
      setSelectedTimeFilterLabel(label);
    } else {
      newFilters.startDate = null;
      newFilters.endDate = null;
      setSelectedTimeFilterLabel('Time Filters');
    }

    setTimeFilters(newTimeFilters);
    setFilters(newFilters);
    setActiveButton(true);

    const result = await fetchMockData(newFilters);
    setData(result);
  };

  return (
    <OverlayPanel ref={op} id="overlay_panel" style={{ width: '300px' }}>
      <Form>
        <div className="time-filter-container">
          <div className="time-filter">
            <InputSwitch checked={timeFilters.oneHour} onChange={(e) => handleTimeChange('oneHour', e.value)} />
            <label>1 Hour</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.twelveHours} onChange={(e) => handleTimeChange('twelveHours', e.value)} />
            <label>12 Hours</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.twentyFourHours} onChange={(e) => handleTimeChange('twentyFourHours', e.value)} />
            <label>24 Hours</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.sevenDays} onChange={(e) => handleTimeChange('sevenDays', e.value)} />
            <label>7 Days</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.thirtyDays} onChange={(e) => handleTimeChange('thirtyDays', e.value)} />
            <label>30 Days</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.threeMonths} onChange={(e) => handleTimeChange('threeMonths', e.value)} />
            <label>3 Months</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.twelveMonths} onChange={(e) => handleTimeChange('twelveMonths', e.value)} />
            <label>12 Months</label>
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.customTime} onChange={(e) => handleTimeChange('customTime', e.value)} />
            <label>Custom Time</label>
            {timeFilters.customTime && (
              <>
                <Dropdown name="startTime" value={filters.startTime} options={hours} onChange={handleFilterChange} placeholder="Start Time" />
                <Dropdown name="endTime" value={filters.endTime} options={hours} onChange={handleFilterChange} placeholder="End Time" />
              </>
            )}
          </div>
          <div className="time-filter">
            <InputSwitch checked={timeFilters.customDate} onChange={(e) => handleTimeChange('customDate', e.value)} />
            <label>Custom Date</label>
            {timeFilters.customDate && (
              <>
                <Calendar name="startDate" value={filters.startDate} onChange={handleFilterChange} placeholder="Start Date" />
                <Calendar name="endDate" value={filters.endDate} onChange={handleFilterChange} placeholder="End Date" />
              </>
            )}
          </div>
        </div>
      </Form>
    </OverlayPanel>
  );
};

export default TimeFilter;
